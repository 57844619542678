@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $second-layer;

  display: flex;
  justify-content: center;
  align-items: center;

  display: none;
}

.Open {
  display: flex;
}

.Background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $midGreen;

  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.Modal {
  z-index: $second-layer + 1;
  width: 100%;

  @media (min-width: $large-screen) {
    width: fit-content;
  }
}