@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.Hidden {
  opacity: 0;
  bottom: -50px;
  pointer-events: none;
}

.MessageContainer {
  @include normal-text-font;

  display: flex;
  align-items: center;

  padding: $spacing-medium $spacing-large;
  border-radius: 35px;
  margin: $spacing-medium $spacing-large;
}

.Message {
  text-align: center;
}

.Close {
  display: flex;
  cursor: pointer;
  
  margin-left: $spacing-small;
}

.Severity__info {
  color: $midGreen;
  background-color: $sand;
}

.Severity__warning {
  color: $white;
  background-color: $orange;
}

.Severity__successs {
  color: $white;
  background-color: $darkGreen;
}

.Severity__error {
  color: $cream;
  background-color: $orange;

  .Close {
    svg {
      fill: $cream;
    }
  }
}
