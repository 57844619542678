.Theme_pink {
  &--Page {
    background-color: $pink;
  }
}

.Theme_white {
  & div {
    border-color: $cream;
  }

  &--Page {
    color: $white;
  }

  &--Box {
    &__overlay {
      background: linear-gradient(to bottom, alpha-color($light-gray, 0.5), alpha-color($light-gray, 0.1));
      box-shadow: 0 2px 4px alpha-color($black, 0.1);
      backdrop-filter: blur(10px);
    }
  }

  &--Button {
    background-color: $lightGreen;
    color: $white;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: alpha-color($lightGreen, 0.7);
  }

  &--Button__primary {
    background-color: $white;
    color: $darkGreen;
    border-color: $white;
  }

  &--Button__secondary {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }
}

.Theme_darkGreen {
  color: $sand;

  & div {
    border-color: $lightGreen;
  }

  h1 {
    color: $sand;
  }

  h2 {
    color: $sand;
  }

  h3 {
    color: $orange;
  }

  p {
    color: $sand;
  }

  &--Page {
    background-color: $darkGreen;
  }

  &--Box {
    background-color: $midGreen;
    &__overlay {
      background: linear-gradient(to bottom, alpha-color($light-gray, 0.7), alpha-color($light-gray, 0.1));
      backdrop-filter: blur(10px);
      border: 1px solid alpha-color($white, 0.2);
    }
  }

  &--Accent {
    color: $orange;
  }

  &--Icon {
    color: $orange;
  }

  &--Spinner {
    border: 4px solid $lightGreen;
    border-left-color: white;
  }

  &--Button {
    background-color: $lightGreen;
    color: $white;
  }

  &--Button__primary {
    background-color: $orange;
    color: $white;
  }

  &--Button__secondary {
    background-color: $cream;
    color: $orange;

    border: $orange-border;
  }
}

.Theme_cream {
  & div {
    border-color: $lightOrange;
  }

  h1 {
    color: $orange;
  }

  h2 {
    color: $orange;
  }

  h3 {
    color: $lightOrange;
  }

  p {
    color: $darkGreen;
  }

  &--Page {
    background-color: $cream;
  }

  &--Box {
    background-color: $lightCream;
    &__overlay {
      background: linear-gradient(to bottom, alpha-color($light-gray, 0.5), alpha-color($light-gray, 0.3));
      backdrop-filter: blur(10px);
      border: 1px solid alpha-color($white, 0.2);
    }
  }

  &--Accent {
    color: $orange;
  }

  &--Spinner {
    border: 4px solid $pink;
    border-left-color: $white;
  }

  &--Button {
    background-color: $cream;
    color: $darkGreen;
  }

  &--Button__primary {
    background-color: $orange;
    color: $white;
  }

  &--Button__secondary {
    background-color: $cream;
    color: $orange;

    border: $orange-border;
  }

  &--Logo {
    svg {
      width: 100%;
      height: 100%;
      fill: $orange;
    }
  }

  &--Pattern {
    svg {
      fill: $lightOrange;
    }
  }

  &--Accordion {
    &--ItemHeader {
      &__active {
        background-color: $orange;
        color: $cream;
      }
    }
  }
}

.Theme_orange {
  &--Button {
    background-color: $orange;
    color: $cream;
  }
}

.Theme_lightGreen {
  &--Button {
    background-color: $lightGreen;
    color: $cream;
  }
}

.Theme_midGreen {
  &--Spinner {
    border: 4px solid $pink;
    border-left-color: $orange;
  }
}

.Theme_sand {
  color: $darkGreen;

  & div {
    border-color: $lightOrange;
  }

  h1 {
    color: $darkGreen;
  }

  h2 {
    color: $darkGreen;
  }

  h3 {
    color: $midGreen;
  }

  p {
    color: $lightGreen;
  }

  &--Page {
    background-color: $sand;
  }

  &--Box {
    background-color: $pink;
    &__overlay {
      background: linear-gradient(to bottom, alpha-color($light-gray, 0.5), alpha-color($light-gray, 0.3));
      backdrop-filter: blur(10px);
      border: 1px solid alpha-color($white, 0.2);
    }
  }

  &--Accent {
    color: $white;
  }

  &--Icon {
    color: $white;
  }

  &--Spinner {
    border: 4px solid $lightOrange;
    border-left-color: white;
  }

  &--Button {
    background-color: $white;
    color: $darkGreen;
  }

  &--Button__primary {
    background-color: $orange;
    color: $white;
  }

  &--Button__secondary {
    background-color: $cream;
    color: $orange;

    border: $orange-border;
  }

  &--Logo {
    svg {
      width: 100%;
      height: 100%;
      fill: $darkGreen;
    }
  }

  &--Pattern {
    svg {
      fill: $lightOrange;
    }
  }
}
