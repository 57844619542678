@import "styles/_variables.scss"; @import "styles/_fonts.scss";
@import './variables';
@import './fonts';
@import './Themes.module.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.carousel-root,.carousel,.slider-wrapper,.slider {
  height: 100%;
}

h1, h2, h3, h4 {
  font-weight: 500;
  margin: 0;
}

div {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.div:focus {
  outline: none !important;
}


@media (prefers-color-scheme: dark) {
  html {
  }
  body {
    font-size: 16px;
  }
}
